"use client";

import { BidAuctionCard, Column, Slider } from "daskapital-ds";
import React, { useEffect, useState } from "react";

import Link from "next/link";
import { useGlobalContext } from "@/lib/globalContext";

interface IAttrs {
  className?: string;
  style?: Record<string, unknown>;
  slidesToShow?: number;
  slidesToShowMobile?: number;
  spacing?: string;
  dots?: boolean;
  arrows?: boolean;
  isFeatured?: boolean;
  limit?: number;
  offset?: number;
}

interface IBlockAuctionCards {
  attrs: IAttrs;
}

const BlockBidAuctionCard: React.FC<IBlockAuctionCards> = ({ attrs }) => {
  const [auctions, setAuctions] = useState<any[]>([]);
  const state = useGlobalContext();

  const {
    slidesToShow = 3,
    slidesToShowMobile = 1,
    spacing = "1rem",
    arrows = true,
    dots = true,
    limit = 12,
    offset = 0,
    isFeatured = false,
  } = attrs;

  //todo: passar para formatBlockData
  useEffect(() => {
    const fetchData = async () => {
      try {
        const newLimit = limit ? `&limit=${limit}` : "";
        const newOffset = offset ? `&offset=${offset}` : "";
        const newIsfeatured = isFeatured ? `&featured=${isFeatured}` : "";

        const url = `${process.env.NEXT_PUBLIC_DASKPITAL_PLATFORM_API}${newLimit}${newOffset}${newIsfeatured}`;
        const response = await fetch(url);
        const data = await response.json();
        setAuctions(data.results ?? []);
      } catch (e) {
        console.log(e)
      }
    };


    fetchData();

  }, []);

  if (!auctions || auctions.length === 0) {
    return null;
  }

  return (
    <Slider
      slidesToShow={slidesToShow}
      slidesToScroll={slidesToShow}
      dots={dots}
      infinite={false}
      arrows={arrows}
      mediaQueries={{
        1600: {
          slidesToShow: 3,
          slidesToScroll: 3
        },
        1200: {
          slidesToShow: 2,
          slidesToScroll: 2
        },
        680: {
          arrows: false,
          slidesToShow: slidesToShowMobile,
          slidesToScroll: slidesToShowMobile,
        },
      }}
      spacing={spacing}
    >
      {auctions.map((auction) => (
        <div key={auction.id} className="p-2">
          <BidAuctionCard
            title={auction?.title}
            imageElement={(
              <img
                width="100%"
                height="100%"
                sizes="100vw"
                decoding="async"
                src={auction?.supplier?.logo_url || "/assets/placeholder.png"}
                alt={auction?.title}
              />
            )}
            typeLabel={auction?.auction_type === 'assets' ? state?.dict?.custom?.auctionLoanLabel : state?.dict?.custom?.auctionInvoiceLabel}
            dateLabel={state?.dict?.custom?.auctionDateLabel}
            rateLabel={state?.dict?.custom?.auctionRateLabel}
            buttonLabel={state?.dict?.custom?.auctionButtonLabel}
            auction={{
              "auction_type": auction?.auction_type,
              "state": auction?.state,
              "end_date": auction?.end_date,
              "amount_requested": auction?.amount_requested,
              "amount_achieved": auction?.amount_achieved,
              "cur_apr": auction?.cur_apr,
              "supplier": {
                "commercial_name": auction?.supplier?.commercial_name,
                "risk_score": auction?.supplier?.risk_score,
              }
            }}
            linkElement={
              <Link
                href={`${process.env.NEXT_PUBLIC_DASKPITAL_PLATFORM}/${auction.id}`}
                aria-label={`Read more about ${auction.title}`}
                role="link"
                target="_blank"
                className="linker">
              </Link>
            }
          />
        </div>
      ))}
    </Slider>
  );
};

export default BlockBidAuctionCard;
